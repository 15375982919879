import React, { useState } from 'react';
import { FaPerson, FaCubes, FaLocationDot } from 'react-icons/fa6';
import * as GiIcons from 'react-icons/gi';
import * as FaIcons from 'react-icons/fa';

const iconSets = {
  ...GiIcons,
  ...FaIcons,
};

// For Quote display
const QuoteCard = ({ quote, context }) => (
  <div className="flex flex-col justify-center items-center bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 p-6 text-center">
    <i className="text-lg font-semibold max-w-sm">"{quote}"</i>
    <blockquote className="text-sm text-gray-600 dark:text-gray-400 mt-2 max-w-sm">
      {context}
    </blockquote>
  </div>
);

/**
 * CategoryBox handles:
 *  - The list of items
 *  - Active item tracking (click)
 *  - Hover item tracking (hover)
 *  - Displaying either the hover item’s description or the active item’s description
 *  - The `defaultIcon` is used if an `item.icon` can't be found in iconSets.
 */
function CategoryBox({ title, icon, defaultIcon: DefaultIcon, items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);

  if (!items || items.length === 0) return null;

  // Sort alphabetically (case-insensitive)
  const sortedItems = [...items].sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
  );

  // The index whose description we’re currently displaying
  const displayIndex = hoverIndex !== null ? hoverIndex : activeIndex;

  return (
    // Removed md:w-1/3 and just used a normal container.
    <div className="mb-12 md:mb-0">
      <div className="justify-center pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
        <h2 className="text-xl font-semibold text-center flex items-center gap-2">
          {icon}
          {title}
        </h2>
      </div>

      <textarea
        readOnly
        className="mb-3 p-3 border border-gray-200 rounded-md bg-gray-50 text-sm w-full
                   overflow-y-auto resize-y leading-5 focus:ring-0 focus:border-gray-200"
        value={sortedItems[displayIndex]?.description || 'No description'}
        rows={3}
      />

      <div className="flex flex-wrap gap-2 justify-start">
        {sortedItems.map((item, idx) => {
          const IconComponent = iconSets[item.icon] || DefaultIcon;
          return (
            <button
              key={idx}
              onClick={() => setActiveIndex(idx)}
              onMouseEnter={() => setHoverIndex(idx)}
              onMouseLeave={() => setHoverIndex(null)}
              className={`py-2 px-3 border font-semibold shadow-sm border-gray-300 w-full rounded-md text-sm cursor-pointer flex items-center gap-2
                ${
                  activeIndex === idx
                    ? 'bg-gray-200 shadow-md'
                    : 'bg-white hover:bg-gray-100'
                }`}
            >
              <IconComponent className="flex-shrink-0 w-4 h-4" />
              <p className="truncate">{item.name}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default function RecapInfo({ npcs, locations, items, quotes }) {
  return (
    <>
      {/* Quotes Section */}
      {quotes && quotes.length > 0 && (
        <>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut mt-8">
            <h2 className="text-xl font-semibold text-center">Quotes</h2>
          </div>

          <div className="grid sm:grid-cols-2 gap-4 mb-8">
            {quotes.map((q, i) => (
              <QuoteCard key={i} quote={q.quote} context={q.context} />
            ))}
          </div>
        </>
      )}

      {/* NPCs, Locations, Items each in their own “CategoryBox” */}
      {/* Using a grid with 3 columns on medium+ screens */}
      <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        <CategoryBox
          title="NPCs"
          icon={<FaPerson className="h-5 w-5" />}
          defaultIcon={FaPerson}
          items={npcs}
        />

        <CategoryBox
          title="Items"
          icon={<FaCubes className="h-5 w-5" />}
          defaultIcon={FaCubes}
          items={items}
        />

        <CategoryBox
          title="Locations"
          icon={<FaLocationDot className="h-5 w-5" />}
          defaultIcon={FaLocationDot}
          items={locations}
        />
      </div>
    </>
  );
}
