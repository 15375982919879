import React from 'react';
import './App.css';
import { useEffect, useState } from 'react';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import { HomePage } from './pages/HomePage';
import Pricing from './pages/PricingPage';
import CampaignPage from './pages/CampaignPage';
import SessionPage from './pages/SessionPage';
import TestPage from './pages/TestPage';
import ContactPage from './pages/ContactPage';
import FAQPage from './pages/FAQPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import TermsPage from './pages/TermsPage';
import CreditPage from './pages/CreditPage';
import DevLogPage from './pages/DevLogPage';
import PartnersPage from './pages/PartnersPage';
import NotFoundPage from './pages/NotFoundPage';

import { SessionContextProvider } from './SessionContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CookieBanner from './components/CookieBanner';
import { ToastContainer } from 'react-toastify';

import Cookies from 'js-cookie';

// Import Firebase Analytics
import { getAnalytics } from 'firebase/analytics';
import { app } from './firebaseConfig'; // Firebase config file

// Import ReactPixel for Meta Pixel
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, {}, options);

function App() {
  const [cookieConsent, setCookieConsent] = useState(undefined);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    setCookieConsent(consent);
  }, []);

  useEffect(() => {
    if (cookieConsent === 'accepted') {
      ReactPixel.grantConsent();
      ReactPixel.pageView();
      getAnalytics(app);
    }
  }, [cookieConsent]); // Dependency ensures re-evaluation when cookieConsent changes

  return (
    <SessionContextProvider>
      <Router>
        <div className="App selection:bg-[rgba(211,211,211,0.5)]">
          <div className="min-h-screen">
            <Navbar />
            <CookieBanner />
            <ToastContainer />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/campaigns/:campaignId" element={<CampaignPage />} />
              <Route
                path="/campaigns/:campaignId/:sessionId"
                element={<SessionPage />}
              />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="/terms-of-service" element={<TermsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/credit" element={<CreditPage />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/dev-log" element={<DevLogPage />} />
              <Route path="/partners" element={<PartnersPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </SessionContextProvider>
  );
}

export default App;
