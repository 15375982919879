import React, { useState, useEffect } from 'react';
import { auth, storage, db } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, getBlob } from 'firebase/storage';

import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';

import Lottie from 'lottie-react';
import loadingAnimationBlue from '../assets/loading_lottie_blue.json';
import loadingAnimationRed from '../assets/loading_lottie_red.json';
import loadingAnimationGreen from '../assets/loading_lottie_green.json';
import loadingAnimationYellow from '../assets/loading_lottie_yellow.json';
import scrybeQuillAnimationData from '../assets/ScrybeQuillLottie.json';

import TextBox from '../components/TextBox';
import ContinueGenerationModal from '../components/ContinueGenerationModal';
import RecapInfo from './RecapInfo';

import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';
import { ReactComponent as WizardMicrophoneIcon } from '../assets/WizardMicrophoneIcon.svg';

import { IoMusicalNotes } from 'react-icons/io5';
import { HiDownload } from 'react-icons/hi';
import { FaCheck, FaRegCopy } from 'react-icons/fa6';
import {
  sanitizeSessionName,
  downloadContent,
  copyToClipboard,
} from '../utils';

import moment from 'moment';

// -----------------------
// HELPERS
// -----------------------
function textToHTML(text) {
  // Converts newline-delimited text to HTML paragraphs.
  if (!text) return '';
  return text
    .split('\n')
    .map((line) => `<p>${line}</p>`)
    .join('');
}

async function generateDownloadLink(filePath) {
  const fileRef = ref(storage, filePath);
  return getDownloadURL(fileRef).catch((error) => {
    console.error('Error generating download link: ', error);
  });
}

function SessionPage() {
  const { campaignId, sessionId } = useParams();

  // Session states
  const [sessionData, setSessionData] = useState({});
  const [sessionName, setSessionName] = useState('');
  const [sessionDate, setSessionDate] = useState('');
  const [transcription, setTranscription] = useState('');

  // Summaries
  const [summary, setSummary] = useState('');
  const [shortSummary, setShortSummary] = useState('');
  const [manualEdit, setManualEdit] = useState(false);

  // For toggling which text to show in the editor
  const [showSummary, setShowSummary] = useState(true);

  // We'll keep track of the original text so we can discard changes
  const [originalText, setOriginalText] = useState('');

  // Tiptap / Editor states
  const editor = useEditor({
    extensions: [StarterKit, CharacterCount],
    content: '', // empty initially; we’ll set it in useEffect
    editable: false, // We set it dynamically via manualEdit
    onUpdate({ editor }) {
      // We do not store HTML in Firestore; we store plain text.
      // So for the "editorContent," we'll rely on editor.getText().
      setEditorContent(editor.getText());
      setCharCount(editor.storage.characterCount.characters());
    },
  });
  const [editorContent, setEditorContent] = useState('');
  const [charCount, setCharCount] = useState(0);
  const maxCharacters = 3500;

  // Other fields
  const [narrationAudioURL, setNarrationAudioURL] = useState('');
  const [narrationAudioFilePath, setNarrationAudioFilePath] = useState('');
  const [isolatedNarrationAudioFilePath, setIsolatedNarrationAudioFilePath] =
    useState('');
  const [narrationVideoURL, setNarrationVideoURL] = useState('');
  const [narrator, setNarrator] = useState(null);
  const [backgroundMusicName, setBackgroundMusicName] = useState(null);

  // Info arrays
  const [npcs, setNpcs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [quotes, setQuotes] = useState([]);

  // Copy-to-clipboard
  const [copied, setCopied] = useState(false);

  // For a "show unsaved changes" warning
  const [showWarning, setShowWarning] = useState(false);

  // -----------------------
  // FIRESTORE LISTENER
  // -----------------------
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        // Not logged in
        return;
      }
      const sessionDocRef = doc(
        db,
        `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`
      );

      const unsubscribeDoc = onSnapshot(
        sessionDocRef,
        async (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setSessionData(data);

            setSessionName(data.name);
            setSessionDate(
              moment(data.timeCreated.toDate()).format('MMMM Do, YYYY')
            );
            setTranscription(data.transcription || '');

            // Recap data
            const recap1 = data.recaps?.recap1 || {};
            setSummary(recap1.summary || '');
            setShortSummary(recap1.summary_short || '');
            setManualEdit(!!recap1.manualEdit);

            // NPCs, Items, Locations, Quotes
            if (recap1.extracted_info) {
              setNpcs(recap1.extracted_info.npcs || []);
              setLocations(recap1.extracted_info.locations || []);
              setItems(recap1.extracted_info.items || []);
              setQuotes(recap1.extracted_info.quotes || []);
            }

            // Narrator & Music
            setNarrator(recap1.narrator);
            setBackgroundMusicName(recap1.backgroundMusicName);

            // Audio & Video
            if (recap1.narrationStoragePath) {
              setNarrationAudioFilePath(recap1.narrationStoragePath);
              const url = await generateDownloadLink(
                recap1.narrationStoragePath
              );
              setNarrationAudioURL(url);
            } else {
              setNarrationAudioURL('');
              setNarrationAudioFilePath('');
            }

            if (recap1.isolatedNarrationStoragePath) {
              setIsolatedNarrationAudioFilePath(
                recap1.isolatedNarrationStoragePath
              );
            } else {
              setIsolatedNarrationAudioFilePath('');
            }

            if (recap1.narratedVideoStoragePath) {
              const videoUrl = await generateDownloadLink(
                recap1.narratedVideoStoragePath
              );
              setNarrationVideoURL(videoUrl);
            } else {
              setNarrationVideoURL('');
            }
          } else {
            console.log('No such session document!');
          }
        },
        (error) => {
          console.error('Error listening to session updates: ', error);
        }
      );

      return () => unsubscribeDoc();
    });

    return () => unsubscribe();
  }, [campaignId, sessionId]);

  // -----------------------
  // SYNC EDITOR CONTENT
  // -----------------------
  useEffect(() => {
    if (!editor) return;

    // Whichever text we are currently showing in the toggle:
    const activeText = showSummary ? summary : shortSummary;

    // Convert newlines to <p> blocks and set the editor content
    editor.commands.setContent(textToHTML(activeText));
    // Record this as the "original" text so discarding can revert to it
    setOriginalText(activeText);

    // Also set the editor's "editable" state to match manualEdit
    editor.setEditable(manualEdit);

    // Reset the warning
    setShowWarning(false);
  }, [editor, summary, shortSummary, showSummary, manualEdit]);

  // -----------------------
  // HANDLE SAVING
  // -----------------------
  function handleSaveChanges() {
    // Check character limit
    if (editorContent.length > maxCharacters) {
      alert(
        `Content exceeds the maximum limit of ${maxCharacters} characters.`
      );
      return;
    }

    // We'll store plain text with newlines in Firestore
    // (Tiptap uses HTML, but editorContent is the .getText() from Tiptap.)
    const fieldName = showSummary ? 'summary' : 'summary_short';

    const sessionDocRef = doc(
      db,
      `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`
    );

    updateDoc(sessionDocRef, {
      [`recaps.recap1.${fieldName}`]: editorContent.trim(),
    })
      .then(() => {
        // Also update local state so we don't see "unsaved changes"
        if (showSummary) {
          setSummary(editorContent.trim());
        } else {
          setShortSummary(editorContent.trim());
        }
        setOriginalText(editorContent.trim());
        setShowWarning(false);
      })
      .catch((err) => console.error('Error updating doc:', err));
  }

  // -----------------------
  // HANDLE DISCARD
  // -----------------------
  function handleDiscardChanges() {
    // Revert to the last known original text
    const revertText = originalText;
    editor.commands.setContent(textToHTML(revertText));
    setEditorContent(revertText); // Update local state so we don't see "changed" status
    setShowWarning(false);
  }

  // This triggers if user has unsaved changes and tries to continue generation
  function maybeShowWarning(e) {
    const activeText = showSummary ? summary : shortSummary;
    if (editorContent !== activeText) {
      // We have unsaved changes
      e.preventDefault();
      setShowWarning(true);
    }
  }

  // Example “generateRecapFromSummary” method (unchanged).
  const generateRecapFromSummary = async () => {
    const sessionDocRef = doc(
      db,
      `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`
    );

    await updateDoc(sessionDocRef, {
      [`recaps.recap1.manualEdit`]: false,
    });

    const endpoint = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL_BASE}/generate_recap_from_summary`;
    await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userID: auth.currentUser.uid,
        campaignID: campaignId,
        sessionID: sessionId,
        useShortSummary: !showSummary,
      }),
    });
  };

  // -----------------------
  // DOWNLOAD FILE
  // -----------------------
  async function downloadFile(filePath, filename) {
    try {
      const fileRef = ref(storage, filePath);
      const blob = await getBlob(fileRef);
      const blobURL = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobURL);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

  // -----------------------
  // RENDER
  // -----------------------
  return (
    <div>
      <div className="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
        {/* -- Session Title & Date -- */}
        <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
          <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
            {sessionName}
          </h1>
          <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-xs mx-auto">
            {sessionDate}
          </p>

          {/* Status spinners */}
          <div className="mt-1">
            {sessionData?.transcription === null && (
              <div className="flex items-center justify-center gap-1">
                <span className="text-xs whitespace-nowrap">Transcribing</span>
                <Lottie
                  animationData={loadingAnimationBlue}
                  autoplay={true}
                  loop={true}
                  className="h-6 w-6 -mb-0.5"
                />
              </div>
            )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary === null && (
                <div className="flex items-center justify-center gap-1">
                  <span className="text-xs whitespace-nowrap">Summarizing</span>
                  <Lottie
                    animationData={loadingAnimationRed}
                    autoplay={true}
                    loop={true}
                    className="h-6 w-6 -mb-0.5"
                  />
                </div>
              )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary !== null &&
              manualEdit && (
                <div className="flex items-center justify-center gap-x-1">
                  <span className="text-xs whitespace-nowrap">
                    Review Recap
                  </span>
                  <ScrybeLogo className="w-5 h-5" />
                </div>
              )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary !== null &&
              sessionData?.recaps?.recap1?.narrationStoragePath === null &&
              !manualEdit && (
                <div className="flex items-center justify-center gap-1">
                  <span className="text-xs whitespace-nowrap">Narrating</span>
                  <Lottie
                    animationData={loadingAnimationYellow}
                    autoplay={true}
                    loop={true}
                    className="h-6 w-6 -mb-0.5"
                  />
                </div>
              )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary !== null &&
              sessionData?.recaps?.recap1?.narrationStoragePath !== null &&
              sessionData?.recaps?.recap1?.narratedVideoStoragePath ===
                null && (
                <div className="flex items-center justify-center gap-1">
                  <span className="text-xs whitespace-nowrap">
                    Generating Video
                  </span>
                  <Lottie
                    animationData={loadingAnimationGreen}
                    autoplay={true}
                    loop={true}
                    className="h-6 w-6 -mb-0.5"
                  />
                </div>
              )}
          </div>
        </div>

        {/* -- TRANSCRIPTION -- */}
        <div>
          <div className="justify-center pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Transcription</h2>
          </div>
          {transcription ? (
            <TextBox content={transcription} />
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
            </ul>
          )}
        </div>

        {/* -- TEXT RECAP -- */}
        <div>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Text Recap</h2>
          </div>

          {/* EDITOR */}
          {summary || shortSummary ? (
            <>
              {/* TABS: SUMMARY / SHORT SUMMARY  +  DOWNLOAD/COPY */}
              <div className="w-full flex flex-wrap items-center justify-between gap-2">
                {/* Tab Switch */}
                <div className="rounded-lg inline-flex gap-1 p-0.5 bg-gray-100 font-medium text-gray-800 text-sm">
                  <button
                    onClick={() => {
                      setShowSummary(true);
                      setShowWarning(false);
                    }}
                    className={`rounded-lg px-2.5 py-1.5 text-gray-800 cursor-pointer whitespace-nowrap h-8 ${
                      showSummary ? 'bg-white shadow-sm' : 'hover:bg-gray-200'
                    }`}
                  >
                    Long Recap
                  </button>

                  <button
                    onClick={() => {
                      setShowSummary(false);
                      setShowWarning(false);
                    }}
                    className={`rounded-lg px-2.5 py-1.5 text-gray-800 cursor-pointer whitespace-nowrap h-8 ${
                      !showSummary ? 'bg-white shadow-sm' : 'hover:bg-gray-200'
                    }`}
                  >
                    Short Recap
                  </button>
                </div>

                {/* Download / Copy */}
                <div className="flex flex-row items-center gap-2">
                  <button
                    className="border border-gray-200 h-8 w-8 flex items-center justify-center rounded-md hover:bg-gray-50"
                    onClick={() =>
                      downloadContent(
                        // If the user is viewing the short summary, we'll append "(Short)" to the filename
                        sanitizeSessionName(sessionName) +
                          (showSummary ? '' : ' (Short Recap)') +
                          ' - Recapped on Scrybe.txt',

                        showSummary ? summary : shortSummary
                      )
                    }
                  >
                    <HiDownload className="h-4 w-4" />
                  </button>

                  <button
                    className="border border-gray-200 h-8 w-8 flex items-center justify-center rounded-md hover:bg-gray-50"
                    onClick={() =>
                      copyToClipboard(
                        showSummary ? summary : shortSummary,
                        setCopied
                      )
                    }
                  >
                    {copied ? (
                      <FaCheck className="h-3.5 w-3.5" />
                    ) : (
                      <FaRegCopy className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>
              <div className="dots-corners text-sm border border-y-gray-200 w-full flex flex-col bg-white border-x-2 border-black p-4 mt-3">
                <EditorContent editor={editor} spellCheck="false" />
              </div>

              {/* Save/Discard if there's an unsaved difference */}
              {editorContent !== (showSummary ? summary : shortSummary) && (
                <div className="mt-3 flex justify-between gap-4 flex-col xs:flex-row">
                  {/* Warning + Character Count */}
                  {showWarning && (
                    <div className="flex flex-col text-sm text-center items-center xs:items-start xs:text-left w-full">
                      <p
                        className={`text-sm text-center ${
                          maxCharacters - charCount >= 0
                            ? 'text-gray-500'
                            : 'text-red-500'
                        }`}
                      >
                        {maxCharacters - charCount >= 0
                          ? `${maxCharacters - charCount} characters remaining`
                          : `${Math.abs(maxCharacters - charCount)} characters over limit`}
                      </p>
                      <p className="text-red-500">
                        Please save or discard your changes before continuing.
                      </p>
                    </div>
                  )}
                  {!showWarning && (
                    <p
                      className={`text-sm text-center ${
                        maxCharacters - charCount >= 0
                          ? 'text-gray-500'
                          : 'text-red-500'
                      }`}
                    >
                      {maxCharacters - charCount >= 0
                        ? `${maxCharacters - charCount} characters remaining`
                        : `${Math.abs(maxCharacters - charCount)} characters over limit`}
                    </p>
                  )}

                  <div className="flex gap-2 flex-col xs:flex-row">
                    <button
                      type="button"
                      onClick={handleDiscardChanges}
                      className="py-2 px-3 max-h-10 whitespace-nowrap inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                    >
                      Discard changes
                    </button>
                    <button
                      onClick={handleSaveChanges}
                      type="button"
                      className="py-2 px-3 max-h-10 inline-flex items-center gap-x-2 text-sm justify-center font-semibold rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black disabled:opacity-50 disabled:pointer-events-none"
                      disabled={charCount > maxCharacters}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              {/* Continue Generation if in manualEdit */}
              {manualEdit && (
                <div className="flex justify-center w-full mt-5">
                  <button
                    onClick={maybeShowWarning}
                    // Only show the modal if there's no unsaved changes
                    {...(editorContent ===
                    (showSummary ? summary : shortSummary)
                      ? { 'data-hs-overlay': '#hs-modal-continue-generation' }
                      : {})}
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black"
                  >
                    {/* <Lottie
                      src={scrybeQuillAnimationData}
                      animationData={scrybeQuillAnimationData}
                      autoplay={true}
                      loop={true}
                      className="flex-shrink-0 h-6 w-6 md:w-7 md:h-7 invert"
                    /> */}
                    Continue Generation
                    <Lottie
                      src={scrybeQuillAnimationData}
                      animationData={scrybeQuillAnimationData}
                      autoplay={true}
                      loop={true}
                      className="flex-shrink-0 w-7 h-7 invert"
                    />
                  </button>
                </div>
              )}
            </>
          ) : (
            <ul className="space-y-3 animate-pulse mt-3">
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
            </ul>
          )}
        </div>

        {/* -- AUDIO RECAP -- */}
        <div>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Audio Recap</h2>
          </div>

          {narrationAudioURL && (
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <audio controls>
                <source src={narrationAudioURL} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}

          {narrationAudioURL && isolatedNarrationAudioFilePath ? (
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <div className="hs-dropdown relative inline-flex">
                <button
                  id="hs-dropdown-default"
                  type="button"
                  className="hs-dropdown-toggle py-2 px-3 flex-1 flex items-center justify-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  aria-label="Dropdown"
                >
                  <HiDownload className="h-4 w-4" />
                  Download
                </button>

                <div
                  className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="hs-dropdown-default"
                >
                  <div className="p-1 space-y-0.5 flex flex-col">
                    <button
                      onClick={() =>
                        downloadFile(
                          narrationAudioFilePath,
                          sanitizeSessionName(sessionName) +
                            ' - Recapped on Scrybe.mp3'
                        )
                      }
                      className="flex items-center gap-x-2 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none"
                    >
                      <HiDownload className="h-4 w-4" />
                      Audio Recap
                    </button>
                    <button
                      onClick={() =>
                        downloadFile(
                          isolatedNarrationAudioFilePath,
                          sanitizeSessionName(sessionName) +
                            ' (Narration Only) - Recapped on Scrybe.mp3'
                        )
                      }
                      className="flex items-center gap-x-2 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none"
                    >
                      <HiDownload className="h-4 w-4" />
                      Narration Only
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : narrationAudioURL ? (
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <button
                onClick={() =>
                  downloadFile(
                    narrationAudioFilePath,
                    sanitizeSessionName(sessionName) +
                      ' - Recapped on Scrybe.mp3'
                  )
                }
                className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
              >
                <HiDownload className="h-4 w-4" />
                Download
              </button>
            </div>
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
            </ul>
          )}
        </div>

        {/* -- VIDEO RECAP -- */}
        <div>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Video Recap</h2>
          </div>
          {narrationVideoURL ? (
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <video
                controls
                playsInline
                className="shadow-2xl rounded-3xl w-[40rem]"
              >
                <source src={narrationVideoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button
                onClick={() =>
                  downloadFile(
                    narrationVideoURL,
                    sanitizeSessionName(sessionName) +
                      ' - Recapped on Scrybe.mp4'
                  )
                }
                className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
              >
                <HiDownload className="h-4 w-4" />
                Download
              </button>
            </div>
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full"></li>
            </ul>
          )}
        </div>

        {/* -- Narrator & Music -- */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-4 sm:gap-12 mt-12">
          <div className="text-center rounded-md border border-gray-200 p-5">
            <div className="flex justify-center items-center w-8 h-8 bg-gray-50 border border-gray-200 rounded-full mx-auto">
              <WizardMicrophoneIcon className="h-4 w-4" />
            </div>
            <div className="mt-3">
              <h3 className="text-sm flex justify-center font-semibold text-gray-800">
                {narrator ? narrator.name : 'Narrator'}
              </h3>
            </div>
          </div>

          <div className="text-center rounded-md border border-gray-200 p-5">
            <div className="flex justify-center items-center w-8 h-8 bg-gray-50 border border-gray-200 rounded-full mx-auto">
              <IoMusicalNotes className="h-4 w-4 " />
            </div>
            <div className="mt-3">
              <h3 className="text-sm flex justify-center font-semibold text-gray-800">
                {backgroundMusicName ? backgroundMusicName : 'Background Music'}
              </h3>
            </div>
          </div>
        </div>

        {/* -- Quotes, NPCs, Items, Locations -- */}
        <RecapInfo
          transcription={transcription}
          summary={summary}
          npcs={npcs}
          locations={locations}
          items={items}
          quotes={quotes}
          sessionDate={sessionDate}
        />
      </div>

      {/* -- Continue Generation Modal -- */}
      <ContinueGenerationModal
        generateRecapFromSummary={generateRecapFromSummary}
        usingShortSummary={!showSummary}
      />
    </div>
  );
}

export default SessionPage;
