// ForgotPasswordModal.jsx
import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const ForgotPasswordModal = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('An email has been sent to reset your password.');
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'auth/user-not-found':
          setError('No user found with this email address.');
          break;
        case 'auth/invalid-email':
          setError('The email address is invalid.');
          break;
        default:
          setError('An error occurred while sending the reset email.');
      }
    }
  };

  return (
    <div>
      {/* Modal */}
      <div
        id="hs-modal-forgot-password"
        className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        tabIndex="-1"
        aria-labelledby="hs-modal-forgot-password-label"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 sm:p-7">
              <div className="text-center">
                <h3
                  id="hs-modal-forgot-password-label"
                  className="block text-2xl font-bold text-gray-800"
                >
                  Reset Password
                </h3>
                <p className="mt-2 text-sm text-gray-600">
                  Enter your email address and we'll send you a link to reset
                  your password.
                </p>
              </div>
              <div className="mt-5">
                {message && (
                  <p className="text-green-600 text-sm mb-4">{message}</p>
                )}
                {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
                {/* Form */}
                <form onSubmit={handlePasswordReset}>
                  <div className="grid gap-y-4">
                    {/* Email */}
                    <div>
                      <label
                        htmlFor="reset-email"
                        className="block text-sm mb-2"
                      >
                        Email address
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          id="reset-email"
                          name="reset-email"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white focus:outline-none focus:bg-gray-700"
                    >
                      Send reset email
                    </button>
                    {/* Cancel Button */}
                  </div>
                </form>
                {/* End Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
    </div>
  );
};

export default ForgotPasswordModal;
