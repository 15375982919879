import React from 'react';
import { Link } from 'react-router-dom';

function CookiePolicyPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Cookie Policy
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[15em] mx-auto">
          Last updated: January 21, 2025
        </p>
      </div>

      <div className="grid gap-4 md:gap-8">
        <div>
          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Introduction
          </h2>
          <p className="mb-5 dark:text-gray-400">
            This Cookie Policy explains what cookies are and how we use them. By
            accepting the use of cookies via the cookies popup, you are agreeing
            to allow Scrybe to use cookies and similar technologies as detailed
            in this policy.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            What Are Cookies?
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Cookies are small text files that are placed on your device when you
            visit a website. They help the site recognize your device on
            subsequent visits, remember your preferences, and streamline your
            experience. Some cookies are session-based (meaning they are deleted
            once you close your browser), while others are persistent (stored
            until they expire or you manually delete them).
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            How We Use Cookies
          </h2>
          <p className="mb-5 dark:text-gray-400">
            We use cookies for various purposes, including:
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <strong>Essential Cookies:</strong> To enable certain site
              features, such as user authentication or access to secure areas of
              the website.
            </li>
            <li className="mb-2 dark:text-gray-400">
              <strong>Analytics Cookies:</strong> To understand how our users
              interact with our website, measure performance, and improve our
              services.
            </li>
            <li className="mb-2 dark:text-gray-400">
              <strong>Marketing Cookies:</strong> To personalize and measure the
              effectiveness of advertising, including retargeting campaigns.
            </li>
          </ul>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Third-Party Cookies
          </h2>
          <p className="mb-5 dark:text-gray-400">
            In addition to our own cookies, we use third-party services that may
            place their own cookies on your device. We currently use:
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <strong>Firebase Analytics:</strong> Provided by Google Firebase,
              these cookies help us gather analytics data about how users
              interact with our platform. For more details, please review
              Firebase’s documentation and privacy policy.
            </li>
            <li className="mb-2 dark:text-gray-400">
              <strong>Meta Pixel (Facebook Pixel):</strong> Used to measure
              advertising effectiveness, understand user behavior on our site,
              and provide ads on Facebook and other Meta platforms. For more
              information, you can review Meta’s privacy policy.
            </li>
          </ul>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Changes to This Cookie Policy
          </h2>
          <p className="mb-5 dark:text-gray-400">
            We may update our Cookie Policy from time to time to reflect changes
            in our practices, relevant regulations, or for other operational,
            legal, or regulatory reasons. Any changes will be posted here, and
            the “Last updated” date at the top will be revised accordingly.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Contact Us
          </h2>
          <p className="mb-5 dark:text-gray-400">
            If you have any questions or concerns about this Cookie Policy or
            the way we use cookies, please contact us at{' '}
            <i>info@scrybequill.com</i>.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Additional Information
          </h2>
          <p className="mb-5 dark:text-gray-400">
            For more information on how we handle your personal data, please
            refer to our{' '}
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicyPage;
