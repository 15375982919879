import React, { useState } from 'react';
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../firebaseConfig';

import ForgotPasswordModal from './ForgotPasswordModal';
import { useNavigate } from 'react-router-dom';

import PasswordInput from './PasswordInput';

const LogInModal = () => {
  // State variables for login functionality
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const navigate = useNavigate();

  const closeModal = () => {
    const modalElement = document.querySelector('#hs-modal-login');
    if (window.HSOverlay && typeof window.HSOverlay.close === 'function') {
      window.HSOverlay.close(modalElement);
    } else {
      modalElement.classList.add('hidden');
    }

    // Clear form
    setEmail('');
    setPassword('');
    setError('');
  };

  // Function to handle Google login
  const handleGoogle = async (e) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);

      // Reroute to the campaigns
      navigate('/campaigns/recaps');
      closeModal();
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'auth/popup-blocked':
          setError('Google popup was blocked by the browser.');
          break;
        case 'auth/unauthorized-domain':
          setError('The domain of the URL is not authorized.');
          break;
        case 'auth/popup-closed-by-user':
          break;
        case 'auth/cancelled-popup-request':
          break;
        default:
          setError(
            'An unexpected error occurred during sign-in. Please contact support.'
          );
          break;
      }
    }
  };

  // Function to handle Email/Password login
  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message
    setIsLoading(true); // Start loading
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (user.emailVerified) {
        // Reroute to the campaigns
        navigate('/campaigns/recaps');

        closeModal();
      } else {
        // Sign out the user
        await auth.signOut();
        setError('Please verify your email before logging in.');
      }
    } catch (error) {
      console.log(error);
      // Handle different sign-in errors
      switch (error.code) {
        case 'auth/user-not-found':
          setError('User not found. Please check your email address.');
          break;
        case 'auth/invalid-email':
          setError(
            'The email address is invalid. Please provide a valid email.'
          );
          break;
        case 'auth/wrong-password':
          setError('Password is incorrect. Please try again.');
          break;
        case 'auth/missing-password':
          setError('Please enter a password.');
          break;
        case 'auth/invalid-login-credentials':
          setError('Email or password is incorrect. Please try again.');
          break;
        case 'auth/user-disabled':
          setError('This user has been disabled. Please contact support.');
          break;
        case 'auth/too-many-requests':
          setError('Too many failed login attempts. Please try again later.');
          break;
        default:
          setError(
            'An unexpected error occurred during sign-in. Please contact support.'
          );
          break;
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div>
      {/* Modal */}
      <div
        id="hs-modal-login"
        className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        tabIndex="-1"
        aria-labelledby="hs-modal-login-label"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 sm:p-7">
              <div className="text-center">
                <h3
                  id="hs-modal-login-label"
                  className="block text-2xl font-bold text-gray-800 font-inknut"
                >
                  Log in
                </h3>
                <p className="mt-2 text-sm text-gray-600 gap-x-1 flex items-center justify-center">
                  Don't have an account yet?
                  <a
                    className="text-gray-800 decoration-2 hover:underline focus:outline-none focus:underline font-medium"
                    href="#"
                    data-hs-overlay="#hs-modal-signup"
                  >
                    Sign up here
                  </a>
                </p>
              </div>
              <div className="mt-5">
                {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
                <a
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                  href="#"
                  onClick={handleGoogle}
                >
                  <svg
                    className="w-4 h-auto"
                    width="46"
                    height="47"
                    viewBox="0 0 46 47"
                    fill="none"
                  >
                    <path
                      d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                      fill="#34A853"
                    />
                    <path
                      d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                      fill="#EB4335"
                    />
                  </svg>
                  Log in with Google
                </a>
                <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:mr-6 after:flex-1 after:border-t after:border-gray-200 after:ml-6">
                  Or
                </div>
                {/* Form */}
                <form onSubmit={handleEmailSignIn}>
                  <div className="grid gap-y-4">
                    {/* Email */}
                    <div>
                      <label htmlFor="email" className="block text-sm mb-2">
                        Email address
                      </label>
                      <div className="relative">
                        <input
                          id="login-email"
                          name="email"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="username"
                        />
                      </div>
                    </div>
                    {/* Password */}
                    <div>
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="password"
                          className="block text-sm mb-2"
                        >
                          Password
                        </label>
                        <a
                          className="inline-flex items-center gap-x-1 text-sm text-gray-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium"
                          href="#"
                          data-hs-overlay="#hs-modal-forgot-password"
                        >
                          Forgot password?
                        </a>
                      </div>
                      <div className="relative">
                        <PasswordInput
                          password={password}
                          onPasswordChange={setPassword}
                        />
                      </div>
                    </div>
                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white focus:outline-none focus:bg-gray-700"
                    >
                      {isLoading ? (
                        <div
                          className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        ></div>
                      ) : (
                        'Log in'
                      )}
                    </button>
                  </div>
                </form>
                {/* End Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ForgotPasswordModal />
      {/* End Modal */}
    </div>
  );
};

export default LogInModal;
