import React from 'react';
import { useState } from 'react';

import { FaCheck } from 'react-icons/fa';

function ContactPage() {
  // Scroll to top on load
  window.scrollTo(0, 0);

  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    const textToCopy = 'info@scrybequill.com';
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopySuccess(true); // Show success icon
      setTimeout(() => setCopySuccess(false), 500); // Revert to copy icon after half a second
    });
  };

  return (
    <div className="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut">
          Contact
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-xs mx-auto">
          Send an email about usage, payment, generations, or anything else.
        </p>
      </div>

      <p className="mt-3 text-gray-600 dark:text-gray-400"></p>

      <div className="flex justify-center mt-7 sm:mt-12 relative">
        <div className="relative inline-flex space-x-3 p-3 bg-white border rounded-lg shadow-lg shadow-gray-100 dark:bg-slate-900 dark:border-gray-700 dark:shadow-gray-900/[.2]">
          <div className="flex-[1_0_0%]">
            <div className="font-inknut py-2.5 px-4 block w-full border-transparent rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-transparent dark:text-gray-400 dark:focus:ring-gray-600">
              info@scrybequill.com
            </div>
          </div>
          <div className="flex-[0_0_auto]" onClick={handleCopy}>
            <a
              className="w-[46px] h-[46px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href="#"
            >
              {copySuccess ? (
                <FaCheck className="w-3 h-3" />
              ) : (
                <svg
                  className="flex-shrink-0 w-5 h-5 group-hover:rotate-6 transition"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                </svg>
              )}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
