export function sanitizeSessionName(name) {
  if (!name) return '';
  return name
    .replace(/[\\/:*?"<>|\x00-\x1F]/g, '') // Replace invalid characters
    .trim() // Trim leading/trailing spaces
    .replace(/[. ]+$/, ''); // Remove trailing periods or spaces
}

export function downloadContent(filename, content) {
  const blob = new Blob([content], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function copyToClipboard(text, onCopy) {
  navigator.clipboard.writeText(text).then(() => {
    onCopy(true);
    setTimeout(() => {
      onCopy(false);
    }, 500);
  });
}
