import React, { useRef } from 'react';
import Lottie from 'lottie-react';
import wizardMicAnimationData from '../assets/WIzardMicrophoneLottie.json';
import scrybeQuillAnimationData from '../assets/ScrybeQuillLottie.json'; // Import the new animation
import { IoIosMusicalNotes } from 'react-icons/io';
// Removed the ScrybeLogo import since it's no longer used
import { HiOutlineUpload } from 'react-icons/hi';

const Steps = () => {
  // Create refs for both Lottie animations
  const lottieRefWizard = useRef();
  const lottieRefQuill = useRef();

  return (
    <div className="relative">
      {/* Tab Navs */}
      <nav className="grid gap-4" aria-label="Tabs" role="tablist">
        {/* First Step */}
        <div
          type="button"
          className="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 active"
          onMouseEnter={() =>
            lottieRefWizard.current && lottieRefWizard.current.play()
          }
          onMouseLeave={() =>
            lottieRefWizard.current && lottieRefWizard.current.stop()
          }
        >
          <span className="flex">
            {/* Wizard Lottie Animation */}
            <Lottie
              lottieRef={lottieRefWizard}
              animationData={wizardMicAnimationData}
              autoplay={false}
              loop={true}
              className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7"
            />

            <span className="grow ms-6">
              <span className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                1. Record session
              </span>
              <span className="block mt-1 text-gray-800 dark:text-gray-200">
                Record your sessions using a phone or any device with a
                microphone. Don't worry about audio quality or background noise!
              </span>
            </span>
          </span>
        </div>

        {/* Second Step */}
        <div
          type="button"
          className="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        >
          <span className="flex">
            <HiOutlineUpload className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 text-gray-800 dark:text-gray-200" />
            <span className="grow ms-6">
              <span className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                2. Upload to Scrybe
              </span>
              <span className="block mt-1 text-gray-800 dark:text-gray-200">
                Easily upload your recorded session with just a few clicks.
                Scrybe accepts a wide range of audio formats.
              </span>
            </span>
          </span>
        </div>

        {/* Third Step */}
        <div
          type="button"
          className="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        >
          <span className="flex">
            <IoIosMusicalNotes className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 text-gray-800 dark:text-gray-200" />
            <span className="grow ms-6">
              <span className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                3. Choose narrator and music
              </span>
              <span className="block mt-1 text-gray-800 dark:text-gray-200">
                Choose from a wide selection of narrators and background music
                to customize your recap and match the mood of your session.
              </span>
            </span>
          </span>
        </div>

        {/* Fourth Step */}
        <div
          type="button"
          className="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          onMouseEnter={() =>
            lottieRefQuill.current && lottieRefQuill.current.play()
          }
          onMouseLeave={() =>
            lottieRefQuill.current && lottieRefQuill.current.stop()
          }
        >
          <span className="flex">
            {/* Scrybe Quill Lottie Animation */}
            <Lottie
              lottieRef={lottieRefQuill}
              animationData={scrybeQuillAnimationData}
              autoplay={false}
              loop={true}
              className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7"
            />

            <span className="grow ms-6">
              <span className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                4. Done!
              </span>
              <span className="block mt-1 text-gray-800 dark:text-gray-200">
                Your game session is now immortalized with a captivating
                audio/video recap! Share it with your party at the beginning of
                the next session to pick up where you left off.
              </span>
            </span>
          </span>
        </div>
      </nav>
      {/* End Tab Navs */}
    </div>
  );
};

export default Steps;
