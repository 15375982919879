// AnnouncementBanner.jsx

import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';

import Marquee from 'react-fast-marquee';
import Lottie from 'lottie-react';
import scrybeQuillAnimationData from '../assets/ScrybeQuillLottie.json';

const AnnouncementBanner = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const docRef = doc(db, 'announcements', 'banner_announcement');

    // Set up the Firestore onSnapshot listener
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setMessage(snapshot.data().message);
        } else {
          console.log('Document does not exist!');
        }
      },
      (error) => {
        console.error('Error fetching Firestore message:', error);
      }
    );

    // Cleanup function: unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    message &&
    auth.currentUser && (
      <div
        id="ab-full-width-with-dismiss-button-on-blue-bg"
        className="hs-removing:-translate-y-full bg-black w-full sticky top-0 z-50"
      >
        <div className="flex items-center justify-center w-full max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto ">
          <Marquee
            className="overflow-hidden py-2"
            gradient={true}
            gradientColor={'black'}
            gradientWidth={10}
            pauseOnHover={true}
            pauseOnClick={false}
            autoFill={true}
          >
            <div className="flex items-center text-sm text-white">
              <p className="font-inknut font-bold text-white text-center cursor-default">
                {message}
              </p>
              <Lottie
                src={scrybeQuillAnimationData}
                animationData={scrybeQuillAnimationData}
                autoplay={true}
                loop={true}
                className="flex-shrink-0 mx-2 h-6 w-6 md:w-7 md:h-7 invert"
              />
            </div>
          </Marquee>
        </div>
      </div>
    )
  );
};

export default AnnouncementBanner;
