import React from 'react';
import Pricing from '../components/Pricing';

function PricingPage() {
  // UseEffect scroll to top
  window.scrollTo(0, 0);

  return (
    <div>
      <div>
        {/* <!-- Pricing --> */}
        <div className="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
          {/* <!-- Title --> */}
          <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14 max-w-[15em]">
            <h1 className="items-center gap-4 text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut">
              Pricing
            </h1>

            <p className="mt-1 text-gray-600 dark:text-gray-400">
              Top up your ink supply. Each ink generates one recap.
            </p>
          </div>
          {/* <!-- End Title --> */}

          <Pricing />
        </div>
        {/* <!-- End Pricing --> */}
      </div>
    </div>
  );
}

export default PricingPage;
