import React, { useState } from 'react';
import { IoMdEye } from 'react-icons/io';
import { IoMdEyeOff } from 'react-icons/io';

export default function PasswordInput({ password, onPasswordChange }) {
  const [showPassword, setShowPassword] = useState('');

  return (
    <>
      <input
        type={showPassword ? 'text' : 'password'}
        name="password"
        className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500"
        value={password}
        onChange={(e) => onPasswordChange(e.target.value)}
        autoComplete="current-password"
      />

      {
        <button
          type="button"
          className="absolute inset-y-0 right-0 flex items-center p-4 text-gray-400 hover:text-gray-600 focus:outline-none text-sm"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <IoMdEye size={20} /> : <IoMdEyeOff size={20} />}
        </button>
      }
    </>
  );
}
