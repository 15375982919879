import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FiSettings, FiCheck, FiInfo, FiAlertCircle } from 'react-icons/fi';

const SpellingSettings = () => {
  const [spellingList, setSpellingList] = useState('');
  const [originalSpellingList, setOriginalSpellingList] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [charsOverLimit, setCharsOverLimit] = useState(0);
  const textareaRef = useRef(null);
  const saveTimerRef = useRef(null);
  const [showSaveCheckmark, setShowSaveCheckmark] = useState(false);

  const MAX_CHARS = 1200;

  // Load spelling data when component mounts or auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoading(true);
        try {
          const campaignRef = doc(db, `users/${user.uid}/campaigns`, 'recaps');
          const campaignDoc = await getDoc(campaignRef);

          if (campaignDoc.exists()) {
            const data = campaignDoc.data();
            const words =
              data.spellingAndPronunciationDictionary?.words?.spelling || [];
            const wordsText = words.join('\n');
            setSpellingList(wordsText);
            setOriginalSpellingList(wordsText); // Store the original state
            setWordCount(words.length);
            setCharsOverLimit(Math.max(0, wordsText.length - MAX_CHARS));
          }
        } catch (error) {
          console.error('Error fetching spelling list:', error);
        } finally {
          // Add artificial delay to prevent flickering
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      } else {
        setSpellingList('');
        setOriginalSpellingList('');
        setWordCount(0);
        setCharsOverLimit(0);
      }
    });

    return () => unsubscribe();
  }, []);

  // Update word count and character count when spellingList changes
  useEffect(() => {
    const words = spellingList
      .split('\n')
      .map((word) => word.trim())
      .filter((word) => word.length > 0);

    setWordCount(words.length);
    setCharsOverLimit(Math.max(0, spellingList.length - MAX_CHARS));

    // Check if there are unsaved changes
    setHasUnsavedChanges(spellingList.trim() !== originalSpellingList.trim());
  }, [spellingList, originalSpellingList]);

  // Setup modal open/close handlers
  useEffect(() => {
    // Function to handle modal opening
    const handleModalOpen = () => {
      // Store the current state as the original when modal opens
      setOriginalSpellingList(spellingList);
      setHasUnsavedChanges(false);

      // Reset textarea height on modal open
      if (textareaRef.current) {
        const initialHeight = Math.max(200, textareaRef.current.scrollHeight);
        textareaRef.current.style.height = `${initialHeight}px`;
      }
    };

    // Function to handle modal closing
    const handleModalClose = () => {
      // If there are unsaved changes and the close wasn't triggered by the Save button
      if (hasUnsavedChanges && saveStatus !== 'success') {
        // Reset to original state
        setSpellingList(originalSpellingList);
        setSaveStatus('');
      }
    };

    // Get the modal element
    const modalElement = document.getElementById('spelling-settings-modal');

    if (modalElement) {
      // Listen for the 'open.hs.overlay' event (when modal opens)
      modalElement.addEventListener('open.hs.overlay', handleModalOpen);

      // Listen for the 'close.hs.overlay' event (when modal closes)
      modalElement.addEventListener('close.hs.overlay', handleModalClose);

      // Cleanup
      return () => {
        modalElement.removeEventListener('open.hs.overlay', handleModalOpen);
        modalElement.removeEventListener('close.hs.overlay', handleModalClose);
      };
    }
  }, [spellingList, originalSpellingList, hasUnsavedChanges, saveStatus]);

  // Handle textarea input and resize
  const handleTextareaInput = (e) => {
    // Allow typing beyond limit (no cap)
    setSpellingList(e.target.value);
  };

  const handleSaveSpellings = async () => {
    if (!auth.currentUser || charsOverLimit > 0) return;

    setIsLoading(true);
    setSaveStatus('saving');

    try {
      // Clean and process the spelling list
      const words = spellingList
        .split('\n')
        .map((word) => word.trim())
        .filter((word) => word.length > 0);

      // Save to Firestore
      const campaignRef = doc(
        db,
        `users/${auth.currentUser.uid}/campaigns`,
        'recaps'
      );

      await setDoc(
        campaignRef,
        {
          spellingAndPronunciationDictionary: {
            words: {
              spelling: words,
            },
            updatedAt: new Date(),
          },
        },
        { merge: true }
      );

      // Add artificial delay to ensure loading UI is visible for at least 0.5 seconds
      await new Promise((resolve) => setTimeout(resolve, 250));

      setSaveStatus('success');
      setShowSaveCheckmark(true);
      setOriginalSpellingList(spellingList); // Update the original state after saving
      setHasUnsavedChanges(false);

      // Reset save button after 2 seconds
      setTimeout(() => {
        setShowSaveCheckmark(false);
      }, 750);

      // Clear success message after 3 seconds
      if (saveTimerRef.current) clearTimeout(saveTimerRef.current);
      saveTimerRef.current = setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving spelling list:', error);
      setSaveStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    // Reset to original state
    setSpellingList(originalSpellingList);
    setSaveStatus('');
    setHasUnsavedChanges(false);
  };

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (saveTimerRef.current) clearTimeout(saveTimerRef.current);
    };
  }, []);

  return (
    <>
      <button
        type="button"
        className="h-[38px] py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:ring-gray-600"
        data-hs-overlay="#spelling-settings-modal"
        aria-label="Spelling Correction"
      >
        <FiSettings className="h-4 w-4" />
        <span className="whitespace-nowrap">Spelling</span>
      </button>

      <div
        id="spelling-settings-modal"
        className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                Spelling Correction
              </h3>
              <button
                type="button"
                className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                data-hs-overlay="#spelling-settings-modal"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>

            <div className="p-6 overflow-y-auto">
              <div className="flex items-start gap-2 mb-4 bg-gray-100 p-3 rounded-lg dark:bg-gray-900/30">
                <FiInfo className="h-5 w-5 text-gray-600 dark:text-gray-400 flex-shrink-0 mt-0.5" />
                <div className="text-sm text-gray-800 dark:text-gray-300">
                  Add character names, locations, items, etc. below (one per
                  line). Scrybe will automatically correct any spelling mistakes
                  in recaps.
                </div>
              </div>

              <div className="mb-4 relative">
                <div className="flex justify-between mb-2">
                  <label
                    htmlFor="spelling-textarea"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Spelling List
                  </label>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {wordCount} {wordCount === 1 ? 'word' : 'words'}
                  </span>
                </div>

                <textarea
                  ref={textareaRef}
                  id="spelling-textarea"
                  className={`py-3 px-4 max-h-[50px]  block w-full border rounded-lg text-sm focus:ring-2 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:text-gray-400 transition-shadow duration-200 min-h-[200px] resize-none ${
                    charsOverLimit > 0
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500 dark:border-red-700 dark:focus:ring-red-600'
                      : 'border-gray-200 focus:border-gray-500 focus:ring-gray-500 dark:border-gray-700 dark:focus:ring-gray-600'
                  }`}
                  placeholder="Strahd von Zarovich
Vex'ahlia Vessar
Vex
Beauregard Lionett
Beau
Xhorhas
Aragorn II Elessar
Lothlórien"
                  value={spellingList}
                  onChange={handleTextareaInput}
                  disabled={isLoading}
                  spellCheck="false"
                  autoComplete="off"
                ></textarea>
              </div>

              <div className="text-xs text-gray-500 dark:text-gray-400 space-y-2">
                <p>
                  <strong>Tips:</strong>
                </p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>
                    Use proper capitalization for names (e.g., "Gandalf" not
                    "gandalf")
                  </li>
                  <li>Include common variations or nicknames</li>
                </ul>
              </div>
            </div>

            <div className="flex justify-between items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <span
                className={`text-xs ${
                  charsOverLimit > 0
                    ? 'text-red-500 dark:text-red-400 flex items-center gap-1'
                    : saveStatus === 'success'
                      ? 'text-green-600 dark:text-green-400 flex items-center gap-1'
                      : 'text-gray-500 dark:text-gray-400'
                }`}
              >
                {charsOverLimit > 0 ? (
                  <>
                    <FiAlertCircle className="h-3 w-3" />
                    <span>{charsOverLimit} characters over limit</span>
                  </>
                ) : saveStatus === 'success' ? (
                  <>
                    <FiCheck className="h-3 w-3" />
                    All changes saved
                  </>
                ) : hasUnsavedChanges && saveStatus !== 'success' ? (
                  <span className="text-amber-600 dark:text-gray-400">
                    Unsaved changes
                  </span>
                ) : null}
              </span>

              <div className="flex items-center gap-2">
                <button
                  type="button"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:ring-gray-700"
                  data-hs-overlay="#spelling-settings-modal"
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  onClick={handleSaveSpellings}
                  disabled={isLoading || charsOverLimit > 0}
                  className="w-[56px] h-[38px] py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black focus:outline-none focus:ring-2 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="animate-spin inline-block w-4 h-4 border-2 border-current border-t-transparent text-white rounded-full"
                        role="status"
                        aria-label="loading"
                      ></span>
                    </>
                  ) : showSaveCheckmark ? (
                    <FiCheck className="h-4 w-4" />
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpellingSettings;
