import React from 'react';
import { ReactComponent as SpilledInkSignatureIcon } from '../assets/Spilled_Ink_Signature.svg';

import { FaDiscord } from 'react-icons/fa6';

// import react link
import { Link } from 'react-router-dom';

import me from '../assets/me.jpg';

function DevLogPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Dev Log
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[16em] mx-auto">
          Last updated: March 21, 2025
        </p>
      </div>

      {/* About me */}
      <div className="flex flex-col items-center rounded-xl">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch gap-10">
          <div className="w-full lg:w-5/12 flex justify-center mb-5 md:mb-0">
            <img
              src={me}
              alt="Me"
              className="object-cover rounded-lg drop-shadow-md w-72 lg:w-full"
            />
          </div>
          <div className="w-full lg:w-7/12">
            <h3 className="text-lg font-bold dark:text-gray-400 mt-3 md:mt-0">
              Mark
            </h3>
            <p className="text-sm uppercase text-gray-600">
              Founder & Developer
            </p>
            <p className="dark:text-gray-400 mt-5">
              Hey there! My name is Mark (pictured right) and I am the creator
              of Scrybe. I'm a recent software engineering grad who has always
              loved playing TTRPGs like D&D and Blades in the Dark.
            </p>
            <p className="dark:text-gray-400 mt-5">
              Scrybe started out as a small passion project of mine which I
              turned into the site you're on right now. It all began with me
              manually cobbling together recaps for my own group with various
              online tools and metaphorical duct tape.
            </p>

            <p className="dark:text-gray-400 mt-5">
              I am currently the sole developer and maintainer of Scrybe. If you
              are interested in potentially working with me on Scrybe or just
              want to chat, I'd love to hear from you at{' '}
              <a
                href="mailto:mark@scrybequill.com"
                className="text-gray-600 underline"
              >
                mark@scrybequill.com
              </a>
            </p>

            <p className="dark:text-gray-400 mt-5">
              I hope you enjoy using Scrybe as much as I enjoyed creating it!
            </p>

            <div className="flex justify-end items-center mt-7 dark:text-gray-400">
              <p className="mr-1">- Mark</p>
              <SpilledInkSignatureIcon className="w-14 h-14 -mt-5" />
            </div>
          </div>
        </div>
      </div>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      {/* v1.6 */}
      <div>
        <h2 className="text-lg font-semibold dark:text-white flex items-center">
          <span className="font-bold">Version</span>

          <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-sky-100 text-sky-800 inline-flex items-center justify-center">
            v1.6
          </span>
        </h2>
        <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
          March 21, 2025
        </p>
        <ul className="list-disc pl-8 mb-5">
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🌍 Multilingual Support</span> –
            Scrybe now supports other languages! You can now upload a recording
            in a language other than English and Scrybe will output both the
            text recap and audio narration in that language.
          </li>
          Supported languages include:
          <ul className="list-disc pl-8 mt-2">
            <li>Spanish</li>
            <li>French</li>
            <li>German</li>
            <li>Italian</li>
            <li>Dutch</li>
            <li>Portuguese</li>
            <li>and 20+ more.</li>
          </ul>
          <div className="mt-2 italic">
            View the full list in the{' '}
            <Link to="/faq" className="underline hover:text-gray-600">
              FAQ
            </Link>
            .
          </div>
        </ul>
        <ul className="list-disc pl-8 mb-5">
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🛠️ Improved Spelling Correction</span>{' '}
            – Upgraded spelling correction dictionary model from{' '}
            <em>gpt-4o-mini</em> to <em>gpt-4o</em> for more accurate
            corrections.
          </li>
        </ul>
      </div>

      {/* v1.5 */}
      <div>
        <h2 className="text-lg font-semibold dark:text-white flex items-center">
          <span className="font-bold">Version</span>

          <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-pink-100 text-pink-800 inline-flex items-center justify-center">
            v1.5
          </span>
        </h2>
        <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
          March 8, 2025
        </p>
        <ul className="list-disc pl-8 mb-5">
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">📝 Long and Short Text Recaps</span> –
            Want a thorough account or just a quick summary? Scrybe now
            generates you both a long and a short text recap. Choose which of
            two you use to generate the audio/video recap
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">✍️ Spelling Correction</span> – You
            can now define the correct spelling for commonly misspelled names,
            items, and words. Scrybe will automatically apply these corrections
            to all recaps. No correcting your players' names every time!
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">📋 Copy and Download Buttons</span> –
            Added convenient download and copy buttons for the text recaps.
            Download the text recaps as a .txt file or copy the text to your
            clipboard
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🎙️ Isolated Narration Track</span> –
            You can now download your audio recap's narration track without
            background music, giving you the freedom to mix it with your own
            music or sound effects
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🔊 Improved Narrator Clarity</span> –
            Adjusted audio balance by adding a compressor and slightly
            increasing narrator volume relative to background music, ensuring
            clearer and more distinct speech
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">
              ⬆️ Increased Maximum Text Recap Length
            </span>{' '}
            – I've bumped up the limit so you have more room to add personal
            touches or extra details before finalizing
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">
              ⬆️ Increased upload limit to 1.5 GB
            </span>{' '}
            – Should be good for higher quality longer session recordings now
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🗣️ Added new narrator:</span> Spuds
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🎵 Added new background music:</span>{' '}
            Dark Goddess, Hidden Dell, Crow's Foot
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium inline-flex m-0.5 items-center gap-1">
              <FaDiscord className="w-[20px] text-indigo-500" />
              New Scrybe Discord Server
            </span>{' '}
            – Come join our community to share recaps, post memes, ask
            questions, chat, or maybe even find your next TTRPG group!{' '}
            <a
              href="https://discord.gg/dvRGKvrgCu"
              className="underline hover:text-gray-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to join
            </a>
          </li>
        </ul>
      </div>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      {/* v1.4 */}
      <div>
        <h2 className="text-lg font-semibold dark:text-white flex items-center">
          <span className="font-bold">Version</span>
          <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-lime-100 text-lime-800 inline-flex items-center justify-center">
            v1.4
          </span>
        </h2>
        <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
          January 21, 2025
        </p>
        <ul className="list-disc pl-8 mb-5">
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">
              🤝 Introducing the Scrybe Partner Program!
            </span>{' '}
            – Creators of actual play content can now join our program to get
            free unlimited access to Scrybe!{' '}
            <Link to="/partners" className="underline hover:text-gray-600">
              Learn more
            </Link>
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">💬 UI/UX enhancements</span> – No more
            ugly alert popups when submitting incomplete form (replaced by
            warning icons and pulsing borders). Long filenames in the upload
            section are now truncated.
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">📰 New announcement banner</span> –
            Added a marquee-style animated banner in the navbar that appears to
            highlight important updates and give a heads up while maintenance is
            underway
          </li>
          <li className="mb-2 dark:text-gray-400">
            After logging in you are now redirected straight to your recaps
          </li>
          <li className="mb-2 dark:text-gray-400">
            Made the verification email when signing up using email more
            professional and less sketchy looking
          </li>
          <li className="mb-2 dark:text-gray-400">
            Fixed subtle but annoying navbar height jumping as elements load in
          </li>
        </ul>
      </div>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      {/* v1.3 */}
      <div>
        <h2 className="text-lg font-semibold dark:text-white flex items-center">
          <span className="font-bold">Version</span>
          <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-orange-100 text-orange-800 inline-flex items-center justify-center">
            v1.3
          </span>
        </h2>
        <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
          December 10, 2024
        </p>
        <ul className="list-disc pl-8 mb-5">
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🛠️ Major bug fixes</span>
            <ul className="list-disc pl-8 mt-2">
              <li>
                Fixed issue that caused some recaps to get stuck during
                transcription step
              </li>
              <li>
                New Recap form now retains selections on accidental close and
                properly resets after submission
              </li>
            </ul>
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">
              ⬆️ Increased upload limit to 800 MB
            </span>
          </li>

          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🎵 Added new background music:</span>{' '}
            Curse of the Manor, Ghosties and Ghoulies, Let the Mystery Unfold,
            Magic in the Air, Midnight Coven, The Abandoned Manor, The Crystal
            Cave, The Enchanted Kingdom, The Lights of the Village, The
            Lowlands, The Wishing Well, Ways of the Wizard, Witches Cauldron
          </li>
          <li className="mb-2 dark:text-gray-400">
            Made the navbar more functional (and prettier too!)
          </li>
          <li className="mb-2 dark:text-gray-400">
            Added a{' '}
            <Link to="/partners" className="underline hover:text-gray-600">
              Partners page
            </Link>
          </li>
        </ul>
      </div>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div>
        {/* v1.2 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-teal-100 text-teal-800 inline-flex items-center justify-center">
              v1.2
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            November 2, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                📝 Added option to select third person perspective
              </span>{' '}
              - Great for recaps directed to an audience. You can also
              optionally specify your adventuring party's name instead of
              defaulting to "the party".
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                ⬆️ Increased recap detail and length
              </span>
            </li>
            {/* decrease */}
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                💾 Massively optimized video file size
              </span>{' '}
              - Video recaps are now ~65% smaller, now consistently fitting into
              Discord's 10MB upload limit
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">⚙️ Increased TTS stability</span> -
              reduced fast talking and unusual narrator inflections
            </li>
            <li className="mb-2 dark:text-gray-400">
              Swapped to higher quality TTS model for some narrators
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v1.1 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-violet-100 text-violet-800 inline-flex items-center justify-center">
              v1.1
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            September 23, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🛠️ Massively improved recap summarization quality
              </span>{' '}
              (Upgraded from GPT-4o to o1-preview)
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
              Arthur, Gideon, Jack, Jessica, Klaus, Felix, and Reginald
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🔍 Added search bar, filters, and pagination
              </span>{' '}
              to the "My Recaps" page for better organization
            </li>
            <li className="mb-2 dark:text-gray-400">
              Added option to sign up with email and password instead of just
              Google
            </li>
            <li className="mb-2 dark:text-gray-400">
              Made narrator tags more descriptive
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v1.0 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-blue-100 text-blue-800 inline-flex items-center justify-center">
              v1.0
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            May 20, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                ✏️ You can now manually edit the text recaps
              </span>{' '}
              - Correct spelling or add custom text before proceeding to
              generate the audio and video recaps
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🔊 Added audio previews for narrators and background music
              </span>
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
              Alfie, Carter, Claria, Eleanor, Fizzlebert, George, Mira, Nasim,
              Ronan, Silas, and Von
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🎵 Added new background music:
              </span>{' '}
              Adventure, Basilica of the Heavens, Beautiful Village, Cursed
              Island, Dwarven King's Tomb, Geof the Blacksmith, Link Street,
              Magical Forest, Old Creek Grove, Protecting Neverwinter, Pulse of
              the Unknown, Quaint Quest, Queen of the Dead, Spirits Refuge, The
              City of Akaton, The Dragon Hoard, The Herbalist, The Long Path,
              The White City, Tiny Kingdom, Uncertain Tidings, Whispering Door,
              Winterlight, Without God, and Wode
            </li>
            <li className="mb-2 dark:text-gray-400">
              Added an{' '}
              <Link to="/faq" className="underline hover:text-gray-600">
                FAQ page
              </Link>
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v0.1 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Alpha Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-gray-100 text-gray-800 inline-flex items-center justify-center">
              v0.1
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            February 6, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              Scrybe officially launched to the public 😎
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DevLogPage;
