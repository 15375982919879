import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicyPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Privacy Policy
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[15em] mx-auto">
          Last updated: March 8, 2025
        </p>
      </div>

      <div className="grid gap-4 md:gap-8">
        <div>
          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Information Collected
          </h2>
          <p className="mb-5 dark:text-gray-400">
            In the quest to provide you with transcription and narration
            services, Scrybe collects a few key pieces of data:
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">Your email address</li>
            <li className="mb-2 dark:text-gray-400">
              Your associated display name
            </li>
            <li className="mb-2 dark:text-gray-400">
              Your account creation date
            </li>
            <li className="mb-2 dark:text-gray-400">
              Your uploaded session recordings (Temporarily only. They are
              deleted immediately after transcription)
            </li>
            <li className="mb-2 dark:text-gray-400">
              The transcriptions of your session recordings
            </li>
            <li className="mb-2 dark:text-gray-400">That's it!</li>
          </ul>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Payment Information
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe uses Stripe for payment processing. Payment details are
            directly provided to Stripe, and Scrybe does not store these
            details. Please refer to{' '}
            <Link to="https://stripe.com/en-ca/privacy">
              <u>Stripe's privacy policy</u>
            </Link>{' '}
            for information on their data handling practices.
          </p>

          {/* <h2 className="text-lg font-semibold mb-2 dark:text-white">Usage Information</h2>
                        <p className="mb-5 dark:text-gray-400">Information related to how the service is accessed and used (e.g., browser type, access times) may be collected to improve user experience.</p> */}

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Legal Compliance
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Your information may be used to comply with legal obligations,
            resolve disputes, and enforce agreements.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Data Sharing and Disclosure
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Personal information is not sold or rented to third parties. It may
            be shared with third-party services to the extent necessary for
            providing the service, such as Google Cloud or Firebase.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Artificial Intelligence
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe <i>WILL NOT</i> under <i>ANY</i> circumstances use your data
            (including session transcriptions) to train AI models. Companies
            that do this without explicit consent are evil.
          </p>
          <p className="mb-5 dark:text-gray-400">
            Scrybe makes use of OpenAI's API to condense transcriptions into
            recaps. OpenAI affirms that they DO NOT use any data provided to
            their API for training purposes. If you'd like to learn more about
            OpenAI's data policies, please visit OpenAI's page on security and
            privacy{' '}
            <u>
              <a href="https://openai.com/security-and-privacy/">here</a>
            </u>
            .
          </p>
          <p className="mb-5 dark:text-gray-400">
            Scrybe utilizes OpenAI's Whisper transcription model via the Groq
            API (not to be confused with X's Grok). In the event that the Groq
            API is unavailable, Scrybe uses the Lemonfox and OpenAI APIs as
            fallbacks. Groq, LemonFox, and OpenAI do not retain or store any
            transcription content from API requests unless explicitly required
            for service functionality or legal compliance. You can review their
            full privacy policies here:{' '}
            <u>
              <a href="https://groq.com/privacy-policy/">Groq</a>
            </u>
            &nbsp;|&nbsp;
            <u>
              <a href="https://www.lemonfox.ai/privacy">Lemonfox</a>
            </u>
            &nbsp;|&nbsp;
            <u>
              <a href="https://openai.com/security-and-privacy/">OpenAI</a>
            </u>
          </p>

          <p className="mb-5 dark:text-gray-400">
            Scrybe uses ElevenLabs for generating narration TTS audio. Scrybe
            has opted out of data collection for all TTS requests through
            ElevenLabs. You can review their full privacy policy{' '}
            <u>
              <a href="https://elevenlabs.io/privacy-policy">here</a>
            </u>
            .
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Developer Access
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe's developers (so far it's just me, Mark) have access to the
            database and file storage and may view transcriptions and recaps
            when necessary for debugging, customer support, or improving the
            functionality of the service.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Cookies
          </h2>
          <p className="mb-5 dark:text-gray-400">
            For information on how we use cookies, please refer to our{' '}
            <Link to="/cookie-policy" className="underline">
              Cookie Policy
            </Link>
            .
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            International Data Transfer
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Your personal data listed above may be transferred to — and
            maintained on — computers located outside of your state, province,
            country, or other governmental jurisdiction where the data
            protection laws may differ. Scrybe's primary operations are
            conducted in Canada, with cloud functions and file storage hosted in
            the United States.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Your Rights
          </h2>
          <p className="mb-5 dark:text-gray-400">
            You have the right to access, update, or delete your personal
            information at any time manually or by contacting Scrybe.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Data Retention
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe retains personal information only for as long as necessary to
            provide the service and fulfill the purposes outlined in this
            policy.
          </p>
          <p className="mb-5 dark:text-gray-400">
            Session recordings are automatically permanently deleted immediately
            after transcription is complete.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Security
          </h2>
          <p className="mb-5 dark:text-gray-400">
            The security of your data is important. Scrybe uses industry
            standard security measures to keep your data safe.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Changes to Privacy Policy
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe reserves the right to update or change this privacy policy at
            any time. You are advised to review this policy periodically for any
            changes.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Contact
          </h2>
          <p className="mb-5 dark:text-gray-400">
            For any questions or concerns regarding this privacy policy or the
            handling of personal data, please contact Scrybe at{' '}
            <i>info@scrybequill.com</i>.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Agreement
          </h2>
          <p className="mb-5 dark:text-gray-400">
            By using Scrybe, you acknowledge that you have read and agree to
            this privacy policy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
