import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SpilledInkIcon } from '../assets/Spilled_Ink.svg';

function NotFoundPage() {
  // Scroll to top on load
  window.scrollTo(0, 0);

  return (
    <div className="flex flex-col items-center justify-center px-6 sm:px-10 lg:px-12 py-16 md:py-20">
      <div className="flex flex-col gap-4 items-center max-w-2xl text-center">
        {/* Icon */}
        <SpilledInkIcon className="h-16 w-16 md:h-16 md:w-16" />

        {/* Title */}
        <h1 className="text-5xl md:text-7xl font-bold tracking-tight font-inknut">
          404
        </h1>

        <h2 className="text-xl md:text-2xl font-bold font-inknut font text-gray-600">
          Page not found
        </h2>
        <p className="text-gray-600 dark:text-gray-400">
          Oops! This page is missing from the archives.
        </p>

        {/* Back to home button */}
        <Link
          to="/"
          className="flex items-center justify-center whitespace-nowrap py-2 px-3 gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 min-w-[85px]"
        >
          <svg
            className="shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
          Back to home
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
